<template>
  <div v-if="rsMembers" class="fd-w-full fd-bg-white fd-rounded-lg fd-px-4 fd-py-6" style="min-height: 500px;">
    <div class="fd-w-full fd-flex fd-items-center fd-justify-start fd-mb-16">
      <span class="fd-text-lg fd-font-bold fd-text-theme-2">{{ $t('breadcrumb.rsMembers')}}</span>
    </div>
    <div class="fd-w-80 fd-mx-auto">
      <Member :member="rsMembers.contact_point" :isManager="true" />
    </div>
    <div class="fd-grid fd-grid-cols-4 fd-mt-10">
      <Member
        v-for="(member, index) in rsMembers.members"
        :key="index"
        :member="member"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Member from "@/views/components/RsManagement/Member";

export default {
  name: "RsMembers",
  components: {Member},
  created() {
    this.$store.dispatch('rs/getRsMembers', this.$route.params.id)
  },
  computed: {
    ...mapGetters({
      rsMembers: 'rs/rsMembers'
    })
  }
}
</script>

<style scoped>
</style>