<template>
  <div
    class="card fd-w-full fd-pb-4"
    :class="[isManager ? 'fd-pt-16' : 'fd-pt-1']"
  >
    <div
      class="avatar-custom"
      :class="[isManager ? 'top' : 'fd-mx-auto fd-mb-1']"
    >
      <img class="fd-rounded-full" :src="member.avatar" :alt="member.name">
    </div>
    <div class="fd-text-center">
      <span class="fd-block fd-text-lg fd-font-bold fd-text-theme-2">{{ member.name }}</span>
      <span v-if="isManager" class="fd-block fd-text-xs fd-font-normal fd-text-theme-15 fd-mt-1">{{ member.role }}</span>
      <span v-if="isManager" class="fd-text-xs fd-font-medium" style="color: #838883">{{ $t('global.manager') }}</span>
    </div>
    <div
      class="fd-rounded-xl fd-mx-2 fd-mt-1.5 fd-flex fd-items-center fd-justify-center"
      :class="[isManager ? 'fd-py-5' : 'fd-py-1']"
      style="border: 1.5px solid #EFF3FA;"
    >
      <div>
        <div class="information">
          <SvgLoader :name="'smsIcon'" />
          <div>
            <span class="fd-block fd-text-sm fd-font-semibold fd-text-theme-6">{{ member.email }}</span>
            <label class="fd-text-sm fd-font-medium fd-text-theme-10">{{ $t('global.email') }}</label>
          </div>
        </div>
        <div class="information fd-mt-4">
          <SvgLoader :name="'mobileIcon'" />
          <div>
            <span class="fd-block fd-text-sm fd-font-semibold fd-text-theme-6">{{ member.mobile }}</span>
            <label class="fd-text-sm fd-font-medium fd-text-theme-10">{{ $t('global.mobile') }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Member",
  props: {
    member: {
      type: Object,
      required: true
    },
    isManager: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0 4px 14px #EDEDED;
  border-radius: 16px;

  .avatar-custom {
    width: 57px;
    height: 57px;
    border-radius: 100%;
    position: relative;

    img {
      width: 57px;
      height: 57px;
    }

    &.top {
      width: 87px;
      height: 87px;
      position: absolute !important;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);

      img {
        width: 87px;
        height: 87px;
      }
    }
  }
  .information {
    display: grid;
    grid-template-columns: 1fr 5fr;
    align-items: center;
    grid-gap: 1rem;
  }
}
</style>